import { gql } from 'apollo-boost'
import { BREEDER_DATA, CUSTOMER_DATA, MEETING_DATA } from './fragments'

export const GET_BREEDERS = gql`
  query getBreeders {
    breeders {
      ...BreederData
      emailVerification {
        id
        hash
      }
    }
  }
  ${BREEDER_DATA}
`

export const GET_CUSTOMERS = gql`
  query getCustomers {
    customers {
      ...CustomerData
    }
  }
  ${CUSTOMER_DATA}
`
export const GET_ALL_AMBASSADORS = gql`
  query getAllAmbassadors($cursor: String) {
    getAllAmbassadors(cursor: $cursor) {
      results {
        id
        email
        name
        lastName
        code
        verified
        amount
        isRegistered
        createdAt
        updatedAt
      }
      cursors {
        hasNext
        nextCursor
      }
    }
  }
`
export const SEND_GIFT_CARD_MESSAGE = gql`
  mutation sendGiftCardMessage($ambassadorId: Int, $dogApplicationId: Int) {
    sendGiftCardMessage(
      ambassadorId: $ambassadorId
      dogApplicationId: $dogApplicationId
    ) {
      ... on Ambassador {
        id
        email
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const GET_REFERRAL_DETAILS = gql`
  query getReferralDetails {
    getReferralDetails {
      id
      dogApplicationId
      ambassadorId
      customerId
      customerName
      customerLastName
      customerEmail
      giftcardsent
    }
  }
`

export const UPDATE_BREEDER_APPLICATION_STATUS = gql`
  mutation updateBreederApplicationStatus(
    $application: UpdateBreederApplicationStatus
  ) {
    updateBreederApplicationStatus(breederApplication: $application) {
      ... on BreederApplication {
        breeder {
          id
          applicationStatus
        }
      }
      ... on Error {
        errorMessage
      }
      __typename
    }
  }
`

export const GET_MEETINGS = gql`
  query meetingList(
    $first: Int
    $canceled: Boolean
    $after: String
    $before: String
  ) {
    meetings(
      first: $first
      canceled: $canceled
      after: $after
      before: $before
    ) {
      ...MeetingData
    }
  }
  ${MEETING_DATA}
`

// Breed Pictures

export const UPLOAD_BREED_PROFILE_PICTURE = gql`
  mutation uploadBreedProfilePicture($image: UploadBreedProfilePicture) {
    uploadBreedProfilePicture(image: $image) {
      ... on Breed {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const UPLOAD_BREED_COVER_PICTURE = gql`
  mutation uploadBreedCoverPicture($image: UploadBreedProfilePicture) {
    uploadBreedCoverPicture(image: $image) {
      ... on Breed {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPLOAD_BREED_MOBILE_COVER_PICTURE = gql`
  mutation uploadBreedMobileCoverPicture($image: UploadBreedProfilePicture) {
    uploadBreedMobileCoverPicture(image: $image) {
      ... on Breed {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

// State pictures

export const UPLOAD_STATE_COVER_PICTURE = gql`
  mutation uploadStateCoverPicture($image: UploadStateCoverPicture) {
    uploadStateCoverPicture(image: $image) {
      ... on State {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPLOAD_STATE_MOBILE_COVER_PICTURE = gql`
  mutation uploadStateMobileCoverPicture($image: UploadStateCoverPicture) {
    uploadStateMobileCoverPicture(image: $image) {
      ... on State {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_DISCOUNT = gql`
  mutation createDiscount($discount: CreateDiscount) {
    createDiscount(discount: $discount) {
      ... on DiscountType {
        id
        amount
        code
        startDate
        endDate
        isDeleted
        applyDiscountByDefault
        active
        customerSpecific
        createdAt
        updatedAt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($discount: DeleteDiscount) {
    deleteDiscount(discount: $discount) {
      ... on DiscountType {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_DOG_DISCOUNT = gql`
  mutation createDogDiscount($dogDiscount: CreateDogDiscount) {
    createDogDiscount(dogDiscount: $dogDiscount) {
      ... on DogDiscountType {
        id
        dogId
        discountId
        createdAt
        updatedAt
      }

      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_BREED_DISCOUNT = gql`
  mutation createBreedDiscount($breedDiscount: CreateBreedDiscount) {
    createBreedDiscount(breedDiscount: $breedDiscount) {
      ... on BreedDiscountType {
        id
        breedId
        discountId
        createdAt
        updatedAt
      }

      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_DISCOUNTS = gql`
  query getAllDiscounts($active: Boolean, $isDeleted: Boolean) {
    discounts(active: $active, isDeleted: $isDeleted) {
      rows {
        id
        amount
        code
        startDate
        endDate
        applyDiscountByDefault
        isDeleted
        active
        terms
        allowToAllDogs
        isAmbassadorCode
        bannerText
        customerSpecific
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_BREEDS = gql`
  query allBreeds {
    breeds {
      id
      name
      profilePicture {
        small
      }
    }
  }
`

export const GET_DOGS_ADMIN = gql`
  query getAllDogs {
    dogs {
      id
      name
      profilePicture {
        small
      }
    }
  }
`

export const GET_DISCOUNT = gql`
  query getDiscountById($id: Int) {
    getDiscountById(id: $id) {
      id
      amount
      code
      startDate
      endDate
      applyDiscountByDefault
      allowToAllDogs
      terms
      bannerText
      customerSpecific
      dogs {
        id
        name
      }
      breeds {
        id
        name
      }
    }
  }
`

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($discount: UpdateDiscount) {
    updateDiscount(discount: $discount) {
      ... on DiscountType {
        id
        amount
        code
        startDate
        endDate
        isDeleted
        applyDiscountByDefault
        active
        createdAt
        updatedAt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const REMOVE_DOG_DISCOUNT = gql`
  mutation removeDogDiscount($id: Int) {
    removeDogDiscount(id: $id) {
      ... on Result {
        result
      }
    }
  }
`
export const DELETE_AMBASSADOR = gql`
  mutation deleteAmbassador($id: Int, $code: String) {
    deleteAmbassador(id: $id, code: $code) {
      ... on Ambassador {
        id
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`

export const DELETE_DOG_REFERRAL = gql`
  mutation deleteAppRef($id: Int, $dogApplicationId: Int, $customerId: Int) {
    deleteAppRef(
      id: $id
      dogApplicationId: $dogApplicationId
      customerId: $customerId
    ) {
      ... on Referrals {
        id
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`
export const REMOVE_BREED_DISCOUNT = gql`
  mutation removeBreedDiscount($id: Int) {
    removeBreedDiscount(id: $id) {
      ... on Result {
        result
      }
    }
  }
`

export const SET_DOG_UNAVAILABLE = gql`
  mutation markDogUnavailable($id: Int) {
    markDogUnavailable(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const SET_DOG_AVAILABLE = gql`
  mutation markDogAvailable($id: Int) {
    markDogAvailable(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

/* Blacklist */

export const ADD_USER_BLACKLIST = gql`
  mutation createBlacklist($email: String, $name: String, $phone: String) {
    createBlacklist(email: $email, name: $name, phone: $phone) {
      ... on Blacklist {
        name
        email
        phone
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`
export const GET_ALL_USER_BLACKLIST = gql`
  query getUserFromBlacklist {
    getUserFromBlacklist {
      id
      name
      email
      phone
    }
  }
`
export const DELETE_USER_BLACKLIST = gql`
  mutation removeUserBlacklist($id: Int) {
    removeUserBlacklist(id: $id) {
      ... on Blacklist {
        name
        email
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`

/* travel options */

export const CREATE_TRAVEL_OPTION = gql`
  mutation travelOption($travelOptions: TravelOption) {
    travelOption(travelOptions: $travelOptions) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_TRAVEL_OPTIONS = gql`
  query getTravelOptions {
    getTravelOptions {
      homeDelivery {
        id
        rate
        minMiles
        maxMiles
        minPrice
        maxPrice
      }
      pickupNearHome {
        id
        nearPrice
        nearMaxMiles
        nearMinMiles
        startingStates {
          id
          startingStateCode
        }
        endingStates {
          id
          endingStateCode
        }
      }
      airportPickup {
        id
        airPrice
        airMaxMiles
        airMinMiles
        startingStates {
          id
          startingStateCode
        }
        endingStates {
          id
          endingStateCode
        }
        exceptedBreeds {
          id
          breedId
        }
      }
      special {
        id
        type
        specialPrice
      }
      noFlight {
        id
        stateCode
        country
        note
      }
    }
  }
`

export const CREATE_HOME_DELIVERY = gql`
  mutation homeDelivery($homeDelivery: HomeDelivery) {
    homeDelivery(homeDelivery: $homeDelivery) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_HOME_DELIVERY = gql`
  mutation deleteHomeDelivery($id: Int) {
    deleteHomeDelivery(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_PICKUP_NEAR_HOME = gql`
  mutation pickupNearHome($pickupNearHome: PickUpNearHome) {
    pickupNearHome(pickupNearHome: $pickupNearHome) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_PICKUP_NEAR_HOME = gql`
  mutation deletePickupNearHome($id: Int) {
    deletePickupNearHome(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const CREATE_AIRPORT_PICKUP = gql`
  mutation airportPickup($airportPickup: AirportPickUp) {
    airportPickup(airportPickup: $airportPickup) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_AIRPORT_PICKUP = gql`
  mutation deleteAirportPickup($id: Int) {
    deleteAirportPickup(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_SPECIAL = gql`
  mutation special($special: Special) {
    special(special: $special) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_NO_FLIGHT = gql`
  mutation noFlight($noFlight: NoFlight) {
    noFlight(noFlight: $noFlight) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const DELETE_NO_FLIGHT = gql`
  mutation deleteNoFlight($id: Int) {
    deleteNoFlight(id: $id) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_NO_FLIGHT = gql`
  mutation updateNoFlight($noFlight: UpdateNoFlight) {
    updateNoFlight(noFlight: $noFlight) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
